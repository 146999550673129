import React from "react"
import Layout from "../../components/Layout"
import SearchArea from "../../components/SearchArea"

export default function PageTemplate({ pageContext: { language } }) {

	return(
		<Layout
			lang={language}
			availableLanguages={["th", "en"]}
			noSearch={true}
			indexThis={false}
			shareThis={false}
			title={"Search"}
			pathname={"/search/"}
		>
			<SearchArea lang={language} />
		</Layout>
	)
}
