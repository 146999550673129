import React from "react"
import { InstantSearch, connectRange, connectRefinementList, connectCurrentRefinements, connectStats, connectSearchBox, connectHits, connectPagination, connectStateResults } from "react-instantsearch-dom"
import Hits from "../Hits"
import { withLocation, getSearchClient } from "../../util"

import queryString from 'query-string'

import Slider from '@mui/material/Slider';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination'
import Autocomplete from '@mui/material/Autocomplete';

import DebouncedSearchBox from "../DebouncedSearchBox"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

function parseYears(str) {
	const match1 = str.match(/^(\d{4})(-)?(\d{4})?$/)
	if (match1) {
		// console.log('matches regex1', match1)
		return {
			min: parseInt(match1[1]),
			max: match1[2] === "-" ? (match1[3] === undefined ? undefined : parseInt(match1[3])) : parseInt(match1[1])
		}
	}
	else {
		const match2 = str.match(/^-(\d{4})$/)
		if (match2) {
			// console.log('matches regex2')
			return {
				max: parseInt(match2[1])
			}
		}
	}
	return {}
}

function stringifyYears({min, max}) {
	if (min === max)
		return `${min}`
	return `${min || ""}-${max || ""}`
}

function SearchArea({ params, parseOptions, lang }) {

	const cx = classNames.bind(styles);

	const searchClient = getSearchClient(lang)

	const [error, setError] = React.useState(false)

	let refineCollection = null
	let refineAuthors = null
	let refineTopics = null
	let refineTags = null

	function getRefinementFromParams(field) {
		return Array.isArray(params[field]) ? params[field] : [params[field]]
	}

	if (params.collection) {
		refineCollection = getRefinementFromParams('collection')
	}
	if (params.authors) {
		refineAuthors = getRefinementFromParams('authors')
	}
	if (params.topics) {
		refineTopics = getRefinementFromParams('topics')
	}
	if (params.tags) {
		refineTags = getRefinementFromParams('tags')
	}

	function updateQ(newQ) {
		params.q = newQ
	}

	function updateURL() {
		window.history.replaceState(null, "", `?${queryString.stringify(params, parseOptions)}`)
	}

	const Stats = ({ processingTimeMS, nbHits }) => {
		return(
			<div className={cx("stats")}>{nbHits} results found in {processingTimeMS} ms</div>
		)
	}

	function SectionTitle({ title, onClick }) {
		return(
			<div className={cx("sectionTitle")}>
				{title}
				{onClick && <span className={cx("clear")} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>[ Clear ]</span>}
			</div>
		)
	}

	const SuggestList = ({ title, items, attribute, currentRefinement, refine }) => {

		if (!items.length) return null

		return (
            <div className={cx("section")}>
				<SectionTitle title={title} />
				<Autocomplete
					multiple
					options={items.sort((a, b) => {
						return -b.label.localeCompare(a.label, 'th')
					})}
					getOptionLabel={item => item.label}
					isOptionEqualToValue={(option, value) => option.label === value.label}
					onChange={(event, value, reason) => {
						params[attribute] = value.map(v => v.label)
						updateURL()
						refine(value.map(v => v.label))
					}}
					disableClearable
					autoHighlight
					clearOnEscape
					filterSelectedOptions
					// disableOpenOnClick // for https://github.com/mui-org/material-ui/issues/23164
					value={items.filter(item => currentRefinement.includes(item.label))}
					ChipProps={{size: "small"}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							size="small"
							placeholder={`Search ${attribute}`}
						/>
					)}
				/>
			</div>
        );
	}

	const RefinementList = ({ title, showMore, items, currentRefinement, attribute, isFromSearch, refine, searchForItems }) => {

		if (!items.length) return null

		return(
			<div className={cx("section")}>
				<FormGroup>
					<SectionTitle title={title} />
					{items.map((item, i) => (
						<div className={cx("filterItem")} key={`filteritem${i}`}>
							<FormControlLabel
								control={
									<Checkbox
										checked={item.isRefined}
										onChange={e => {
											params[attribute] = item.value
											updateURL()
											refine(item.value)
										}}
										name={item.label}
										size="small"
									/>
								}
								label={
									<div className={cx("checkboxLabel")}>
										{item.label}
										<span className="blob-small-span left-margin">{item.count}</span>
									</div>
								}
								key={item.label}
							/>
						</div>
					))}

				</FormGroup>
			</div>
		)
	};

	function updateParams(items) {
		params = {q: params.q}
		items.forEach(item => {
			if (item.attribute !== "year") {
				params[item.attribute] = item.currentRefinement
			}
			else {
				params.years = stringifyYears(item.currentRefinement)
			}
			return
		})
	}

	const CurrentRefinements = ({ items, refine, createURL }) => {
		React.useEffect(() => {
			updateParams(items)
			updateURL()
		}, [items])
		if (items.length === 0)
			return null
		return (
			<div className={cx("section")}>
				<SectionTitle title="Current Refinements" onClick={() => refine(items)} />
				<div className={cx("chipArray")}>
					{items.map((item, i) => {
						return item.items
										? item.items.map((r, j) => {
												return(
														<Chip
																label={(["topics", "tags"].includes(item.attribute) ? item.attribute.substring(0, item.attribute.length - 1) + ": " : "") + r.label}
																size="small"
																onDelete={() => {
																		refine(r.value)
																}}
																key={`chip${i}-${j}`}
														/>
												)
											})
										: <Chip
												label={item.label.replace(/(\d{4}) <= year <= \1/, `year: $1`).replaceAll('<=', '≤')}
												size="small"
												onDelete={() => {
														refine(item.value)
												}}
											/>;
					})}
				</div>
			</div>
		);

	}

	const RangeSlider = ({ min, max, currentRefinement, canRefine, refine }) => {
		
		const [stateMin, setStateMin] = React.useState(min);
	  const [stateMax, setStateMax] = React.useState(max);

	  React.useEffect(() => {
	    if (canRefine) {
	      setStateMin(currentRefinement.min);
	      setStateMax(currentRefinement.max);
	    }
	  }, [currentRefinement.min, currentRefinement.max]);

	  if (min === max) {
	    return null;
	  }

	  function handleChange(event, newValue) {
	  	const min = newValue[0]
	  	const max = newValue[1]
	    if (currentRefinement.min !== min || currentRefinement.max !== max) {
				params.years = stringifyYears({min: min, max: max})
				updateURL()
	      refine({ min, max });
	      setStateMin(min);
		    setStateMax(max);
	    }
	  }

	  return (
			<div className={cx("section")}>
				<FormGroup>
					<SectionTitle title="Year" />
			    <Slider
		        value={[currentRefinement.min, currentRefinement.max]}
		        min={min}
		        max={max}
		        onChange={handleChange}
		        valueLabelDisplay="on"
						size="small"
						sx={{
							'& .MuiSlider-valueLabel': {
								background: 'transparent',
								top: 40,
								color: styles.textColor,
							}
						}}
		      />
				</FormGroup>
			</div>
	  );
	};

	const AISPagination = ({ currentRefinement, nbPages, refine, ...rest }) => {
		if (nbPages < 2) return null
		if (currentRefinement > nbPages) {
			params.p = nbPages
			updateURL()
			refine(nbPages)
		}
		return(
			<Pagination
				count={nbPages}
				page={currentRefinement}
				onChange={(event, page) => {
					params.p = page
					updateURL()
					refine(page)
				}}
			/>
		)
	}

	const StateResults = ({ error }) => {
		React.useEffect(() => {
			if (error) {
				setError(true)
			}
		}, [error])
		return null
	}
	
	const CustomStateResults = connectStateResults(StateResults)
	const CustomStats = connectStats(Stats)
	const CustomSearchBox = connectSearchBox(DebouncedSearchBox)
	const CustomHits = connectHits(Hits)
	const CustomSuggestList = connectRefinementList(SuggestList)
	const CustomRefinementList = connectRefinementList(RefinementList)
	const CustomRangeSlider = connectRange(RangeSlider)
	const CustomPagination = connectPagination(AISPagination)
	const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);

	const collectionsLabel = {
		pages: "Pages",
		members: "Members",
		abridged: "aBRIDGEd",
		pierspectives: "PIERspectives",
		dp: "Discussion Paper",
		conferences: "Conferences",
		workshops: "Research Workshops",
		forums: "Policy Forums",
		seminars: "Seminars",
		exchanges: "Exchanges",
		announcements: "Announcements",
		blog: "Blog Posts"
	}

	return (
		<InstantSearch searchClient={searchClient} indexName={`all_${lang}`}>
			<div className={cx("grid")}>
				<div className={cx("left")}>
					<CustomCurrentRefinements />
					<CustomRefinementList
						title="Collections"
						attribute="collection"
						limit={20}
						defaultRefinement={refineCollection}
						transformItems={items => items.map(item => ({...item, label: collectionsLabel[item.label]}))}
					/>
					<CustomSuggestList
						title="Authors"
						attribute="authors"
						// operator="and"
						defaultRefinement={refineAuthors}
						limit={1000}
					/>
					<CustomSuggestList
						title="Topics"
						attribute="topics"
						// operator="and"
						defaultRefinement={refineTopics}
						limit={1000}
					/>
					<CustomSuggestList
						title="Tags"
						attribute="tags"
						// operator="and"
						defaultRefinement={refineTags}
						limit={1000}
					/>
					<CustomRangeSlider 
						attribute="year"
						defaultRefinement={params.years && parseYears(params.years)}
					/>
				</div>
				<div className={cx("right")}>
					<div className={cx("searchGroup")}>
						<CustomSearchBox
							defaultRefinement={params.q}
							showLoadingIndicator
							updateQ={updateQ}
							updateURL={updateURL}
						/>
						<CustomStats />
					</div>
					<CustomStateResults />
					<CustomHits lang={lang} error={error} />
					<div className={cx("pagination")}>
						<CustomPagination defaultRefinement={params.p} />
					</div>
				</div>
			</div>
		</InstantSearch>
	)
}
		
export default withLocation(SearchArea)